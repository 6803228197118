footer{
    padding: 2vh 5vw;

    background-color: var(--nav);
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

footer div{
    display: flex;
    flex-direction: row;

    justify-items: center;
    align-items: center;

    gap: 1rem;
}

footer a{
    text-decoration: none;
    color: var(--logo);
    outline: none;
}

footer a.correo{
    color: var(--text);
}

footer a.correo:hover{
    color: var(--logo);
}

footer a:hover, footer a:focus{
    color: var(--text);
}

footer p{
    text-align: center;
    color: var(--text);
    margin: 0;
    font-size: 15px;
}

footer h5{
    color: var(--text);
    margin:0;
}

footer h5:hover{
    color: var(--logo);
}

.footer-foto{
    width: 25px;
    height: 25px;

    
    background-image: url("../Assets/visualismalllogo.webp");
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;

    outline: none;
}

@media only screen and (max-width: 600px){
    footer{
        flex-direction: column;
        gap: 1rem;
    }

    footer div{
        
        gap: 1rem;
    }
}