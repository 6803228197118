#MockUps {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(3, 1fr);
    grid-column-gap: 0px;
    grid-row-gap: 0px;

    width: 100%;

    justify-items: center;
    align-items: center;
}

.img{
    width: 100%;
    height: 40vh;

    background-color: white;
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
}

.img1{
    background-image: url("../Assets/mockup4.webp");
}

.img2{
    background-image: url("../Assets/mockup3.webp");
}

.img3{
    background-image: url("../Assets/mockup2.webp");
}

.content{
    width: 50%;
    height: 40vh;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.content h3{
    text-align: center;
    color: var(--logo);
    font-size: 30px;
    font-weight: 700;
    margin: 0;
    letter-spacing: 0.1rem;
}

.content p{
    text-align: center;
    color: var(--text);
    font-size: 18px;
    font-weight: 500;
    margin: 0;
    letter-spacing: 0.1rem;
    margin-top: 20px;
}

@media only screen and (max-width: 600px){
    #MockUps{
        grid-template-columns: repeat(1, 1fr);
        grid-template-rows: repeat(6, 1fr);
    }

    .img{
        height: 30vh;
    }

    .content{
        width: 90%;
        height: 30vh;
    }

    .content h3{
        font-size: 25px;
    }

    .content p{
        font-size: 16px;
    }

    .img2{
        grid-area: 4 / 1 / 5 / 2;
    }
}