#contact {
    padding: 5vh 0;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
}

.contact-left {
    width: 48%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-end;
}

.contact-left-content {
    width: 70%;
}

.contact-title {
    font-size: 44px;
    font-weight: 700;
    margin: 0;
    letter-spacing: 0.1rem;
    color: var(--text);
    text-align: left;
}

.contact-title span {
    color: var(--logo);
}

.contact-text {
    font-size: 18px;
    font-weight: 500;
    margin: 0;
    letter-spacing: 0.1rem;
    color: var(--text);
    text-align: left;
    margin-top: 2rem;
}

.contact-message {
    margin-top: 3rem;
    color: var(--logo);
}

.contact-right .contact-text {
    width: 86%;
    margin-top: 3rem;
    margin-bottom: 1rem;
}

.contact-text span {
    color: var(--logo);
}

.contact-right {
    width: 48%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.contact-form {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
}

.contact-form-content {
    width: 80%;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2, 1fr);
    grid-column-gap: 50px;
    grid-row-gap: 50px;
}

.contact-input {
    width: 100%;
    height: 100%;
    color: var(--text);
    background-color: var(--back);
    border: 0.2rem solid var(--text);
    border-radius: 0.5rem;
    padding: 10px 15px;
    transition: all 0.3s ease-in-out;
    font-size: 16px;
}

.contact-input:focus {
    outline: none;
    border: 0.2rem solid var(--logo);
}

.nombre {
    grid-area: 1 / 1 / 2 / 2;
}

.apellidos {
    grid-area: 1 / 2 / 2 / 3;
}

.correo {
    grid-area: 2 / 1 / 3 / 3;
}

.contact-button {

    padding: 10px 40px;
    border: 0.2rem solid var(--logo);
    border-radius: 0.5rem;
    background-color: var(--logo);
    color: black;
    font-size: 1.5rem;
    font-weight: 500;
    letter-spacing: 0.1rem;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
}

@media only screen and (max-width: 600px) {
    #contact {
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 40px;
    }

    .contact-left {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .contact-left-content {
        width: 80%;
    }

    .contact-title {
        font-size: 44px;
        font-weight: 700;
        margin: 0;
        letter-spacing: 0.1rem;
        color: var(--text);
        text-align: center;
    }

    .contact-text {
        font-size: 18px;
        font-weight: 500;
        margin: 0;
        letter-spacing: 0.1rem;
        color: var(--text);
        text-align: center;
        margin-top: 2rem;
    }

    .contact-right .contact-text {
        width: 90%;
        padding: 20px 0;
    }

    .contact-right {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

    }

    .contact-form {
        width: 90%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
    }

    .contact-form-content {
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        grid-template-rows: repeat(3, 1fr);
        grid-column-gap: 50px;
        grid-row-gap: 50px;
    }

    .contact-input {
        background-color: transparent;
        border: 0.2rem solid var(--text);
        border-radius: 0.5rem;
        padding: 10px 0 10px 15px;
        transition: all 0.3s ease-in-out;
    }

    .nombre {
        grid-area: 1 / 1 / 2 / 2;
    }

    .apellidos {
        grid-area: 2 / 1 / 3 / 2;
    }

    .correo {
        grid-area: 3 / 1 / 4 / 2;
    }

    .contact-button {
        padding: 10px 40px
    }
}