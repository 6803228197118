#teamSection {
    height: 65vh;
    width: 90%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 40px;
    padding: 5vh 0;
}

.equipo-titulo {
    text-transform: uppercase;
    font-size: 36px;
    font-weight: 700;
    margin: 0;
    letter-spacing: 0.1rem;
    color: var(--logo);
}

.equipo {
    height: 50vh;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.persona {
    width: 30%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1rem;
}

.foto {
    width: 12vw;
    height: 12vw;
    background-image: url("https://www.w3schools.com/howto/img_avatar.png");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    border-radius: 100%;
}

.nombre-linkedin {
    display: flex;
    flex-direction: row;
    gap: 5px;
    text-decoration: none;
}

.persona-nombre {
    font-size: 22px;
    font-weight: 700;
    margin: 0;
    letter-spacing: 0.1rem;
    color: var(--text);
    text-align: center;
}

.persona a{
    text-decoration: none;
}

.nombre-linkedin:hover .persona-nombre {
    color: var(--logo);
}

.nombre-linkedin:hover .linkedin{
    fill: var(--logo);
}

.persona-puesto {
    font-size: 22px;
    font-weight: 500;
    margin: 0;
    letter-spacing: 0.1rem;
    color: var(--logo);
    text-align: center;
}

.persona-desc {
    display: flex;
    flex-direction: column;
    gap: 5px;
}

.persona-estudios {
    font-size: 16px;
    font-weight: 500;
    margin: 0;
    letter-spacing: 0.1rem;
    color: var(--text);
    text-align: center;
}

.persona-descripcion {
    font-size: 16px;
    font-weight: 500;
    margin: 0;
    letter-spacing: 0.1rem;
    color: var(--logo);
    text-align: center;
}

.title{
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.line1{
    width: 40%;
    height: 1vh;

    background-color: var(--logo);
}

.line2{
    width: 60%;
    height: 1vh;

    background-color: var(--logo);
}

.line3{
    width: 80%;
    height: 1vh;

    background-color: var(--logo);
}

.decoration{
    width: 5%;
    height: 100%;

    display: flex;
    flex-direction: column;
    justify-content: space-around;
}

/* Movil */
@media only screen and (max-width: 600px) {
    #teamSection {
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 40px;
    }

    .equipo-titulo {
        text-transform: uppercase;
        font-size: 36px;
        font-weight: 700;
        margin: 0;
        letter-spacing: 0.1rem;
        color: var(--logo);
        text-align: center;
    }

    .equipo {
        height: 100%;
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        gap: 40px;
    }

    .persona {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 1rem;
    }

    .foto {
        width: 30vw;
        height: 30vw;
        background-image: url("https://www.w3schools.com/howto/img_avatar.png");
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
        border-radius: 100%;
    }

    .persona-nombre {
        font-size: 22px;
        font-weight: 700;
        margin: 0;
        letter-spacing: 0.1rem;
        color: var(--text);
        text-align: center;
    }

    .persona-puesto {
        font-size: 22px;
        font-weight: 500;
        margin: 0;
        letter-spacing: 0.1rem;
        color: var(--logo);
        text-align: center;
    }

    .persona-desc {
        display: flex;
        flex-direction: column;
        gap: 5px;
    }

    .persona-estudios {
        font-size: 16px;
        font-weight: 500;
        margin: 0;
        letter-spacing: 0.1rem;
        color: var(--text);
        text-align: center;
    }

    .persona-descripcion {
        font-size: 16px;
        font-weight: 500;
        margin: 0;
        letter-spacing: 0.1rem;
        color: var(--logo);
        text-align: center
    }

    .decoration{
        gap: 0.5vh;
    }
}