#DescriptionSection {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 4%;
    padding: 20px 0px;
    background-color: white;

}

.description-div {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 4%;
}

.description-mockup {
    width: 80%;
    height: 50vh;
    background-image: url("../Assets/mockup.webp");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;

}

.description-right {
    width: 65%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
}

#DescriptionSection h2 {
    text-transform: uppercase;
    width: 90%;
    font-size: 36px;
    font-weight: 1000;
    letter-spacing: 0.1rem;
    color: var(--logo);
    text-align: center;
    margin: 0;
}

.description-right p {
    width: 85%;
    font-size: 16px;
    font-weight: 500;
    letter-spacing: 0.1rem;
    color: black;
    text-align: center;
}

.header-button {
    margin-top: 2rem;
    padding: 1rem 2rem;
    border: 0.2rem solid var(--logo);
    border-radius: 0.5rem;
    background-color: var(--logo);
    color: var(--text);
    font-size: 1.5rem;
    font-weight: 500;
    letter-spacing: 0.1rem;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
}



@media only screen and (max-width: 600px) {
    #DescriptionSection {
        gap: 0;
    }

    .description-div {
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 4%;
    }

    .description-mockup {
        width: 80%;
        height: 25vh;
        background-repeat: no-repeat;
        background-size: contain;
        background-position: center;
    }

    .description-right {
        width: 80%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    #DescriptionSection h2 {
        text-transform: uppercase;
        width: 90%;
        font-size: 36px;
        font-weight: 1000;
        letter-spacing: 0.1rem;
        color: var(--logo);
        text-align: center;
        margin: 0;
    }

    .description-right p {
        width: 85%;
        font-size: 16px;
        font-weight: 500;
        letter-spacing: 0.1rem;
        color: black;
        text-align: center;
    }

    .header-button {
        margin-top: 2rem;
        padding: 1rem 2rem;
        border: 0.2rem solid var(--logo);
        border-radius: 0.5rem;
        background-color: var(--logo);
        color: var(--text);
        font-size: 1.5rem;
        font-weight: 500;
        letter-spacing: 0.1rem;
        cursor: pointer;
        transition: all 0.3s ease-in-out;
    }
}
