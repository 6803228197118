:root {
  --logo: #18C3B2;
  --nav: #3A3E41;
  --back: #282B2E;
  --text: #FFFFFF;
  --extra-color: #E64B4E;
  scroll-behavior: smooth;
}

@font-face {
  font-family: 'Avenir';
  src: url('./Componentes/Fonts/Avenir-Font/AvenirLTStd-Book.otf') format('opentype'),
    url('./Componentes/Fonts/Avenir-Font/AvenirLTStd-Black.otf') format('opentype'),
    url('./Componentes/Fonts/Avenir-Font/AvenirLTStd-Roman.otf') format('opentype');
}

body {
  margin: 0;
  padding: 0;
  font-family: 'Avenir', sans-serif;
  background-color: var(--back);
  color: var(--text);
  background-image: url('./Componentes/Assets/texture2.webp');
}

main {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

h1, h2, h3, h4, h5, h6{
  font-family: 'Open Sans', sans-serif;
}

p{
  font-weight: 500;
}