#mainSection{
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    flex-direction: column;
    height: 85vh;
    padding: 10vh 0 5vh 0;
}

.companyLogo{
    height: 30vh;
    width: 80vw;

    background-image: url("../Assets/visuali-logo.webp");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
}

#mainSection h1{
    margin: 0;
    font-size: 2vw;
}

#mainSection p{
    width: 45%;
    text-align: center;
}

#mainSection p span{
    color: var(--logo);
}

.joinButton2 {
    display: flex;
    justify-content: center;
    align-items: center;
}

.joinButton2 a {

    padding: 15px 40px;
    text-decoration: none;
    width: 50%;
    height: 50%;

    border-radius: 50px;

    display: flex;
    justify-content: center;
    align-items: center;

    background-color: var(--nav);

    font-size: 14px;
    font-weight: 400;
    color: var(--text);

    transition: background-color 0.5s ease;
}

.joinButton2 a:hover,
.joinButton2 a:focus {
    background-color: var(--logo);
    outline: none;
}

@media only screen and (max-width: 600px){
    #mainSection{
        height: 100vh;
    }

    .companyLogo{
        height: 15vh;
        width: 80vw;
    }

    #mainSection h1{
        font-size: 24px;
        text-align: center;
    }

    #mainSection p{
        width: 80%;
        font-size: 16px;
    }

    .joinButton2 {

        display: flex;
        justify-content: center;
        align-items: center;
    }

    .joinButton2 a {

        padding: 20px 40px;
        text-decoration: none;
        width: 50%;
        height: 50%;

        border-radius: 50px;

        display: flex;
        justify-content: center;
        align-items: center;

        background-color: var(--nav);

        font-size: 14px;
        font-weight: 400;
        color: var(--text);

        transition: background-color 0.5s ease;
    }
}